import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TaskManagement from "./tasks/adminTasks";
import {
  Search,
  ChevronRight,
  X,
  User as UserIcon,
  Moon,
  Sun,
  Mail,
  Phone,
  Award,
  MapPin,
  Book,
  Bookmark,
  Briefcase,
  Calendar,
  PlusCircle,
  ToggleLeft,
  ToggleRight,
  UserRoundPlus,
  Save,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  useGetAllUsersQuery,
  useGetMeQuery,
  useGetUsersByPositionQuery,
  useLogoutMutation,
  useUpdateUserPositionMutation,
  useUpdate_PointsMutation,
} from "../../../generated/graphql";

const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
});

interface User {
  user_id: string;
  username: string;
  email_primary: string;
  mobile_number_primary?: string;
  isIITM?: string;
  college?: string;
  points_scored?: number;
  sa_answer?: string;
  sa_answer2?: string;
  sa_answer3?: string;
  sa_referral?: string;
  state?: string;
  saarang_id: string;
  year_of_study?: string;
  positions?: string;
  region?: string;
}

const UserRibbon: React.FC<{
  user: User;
  onClick: (user: User) => void;
  onSetCA: (user: User) => void;
}> = ({ user, onClick, onSetCA }) => {
  const { refetch } = useGetAllUsersQuery();
  const { isDarkMode } = useContext(ThemeContext);
  const [updateUserPosition] = useUpdateUserPositionMutation();
  const handleSetCA = async (user: User) => {
    try {
      const { data } = await updateUserPosition({
        variables: {
          user_id: user.user_id,
          positions:
            "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100", // Setting to SA position
        },
      });
      refetch();
      if (data) {
        console.log("User position updated:", data.updateUserPosition);
      }
    } catch (error) {
      console.error("Error updating position:", error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className={`flex items-center p-6 mb-4 rounded-xl shadow-lg cursor-pointer transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl ${
        isDarkMode
          ? "bg-gray-800 hover:bg-gray-700 text-white"
          : "bg-white hover:bg-gray-50 text-gray-800"
      }`}
      onClick={() => onClick(user)}
    >
      <div className="w-16 h-16 rounded-full mr-6 border-4 border-blue-500 bg-gray-200 flex items-center justify-center overflow-hidden">
        <UserIcon size={32} className="text-gray-500" />
      </div>
      <div className="flex-grow">
        <h3 className="text-xl font-semibold mb-1">{user.username}</h3>
        <p
          className={`text-sm ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          {user.saarang_id}
        </p>
      </div>
      {user.positions ===
        "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100" && (
        <div
          className={`text-sm px-3 py-1 rounded-full ${
            isDarkMode
              ? "bg-blue-900 text-blue-200"
              : "bg-blue-100 text-blue-800"
          }`}
        >
          points: {user.points_scored}
        </div>
      )}

      {user.positions ===
        "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000010" && (
        <motion.button
          whileHover={{ backgroundColor: "#3b82f6" }}
          whileTap={{ backgroundColor: "#1d4ed8" }}
          className="ml-4 p-4 rounded-full bg-blue-500 text-white font-medium"
          onClick={(e) => {
            e.stopPropagation();
            handleSetCA(user);
          }}
        >
          SET TO CA
        </motion.button>
      )}
      <ChevronRight
        className={`ml-4 ${isDarkMode ? "text-gray-400" : "text-gray-400"}`}
        size={24}
      />
    </motion.div>
  );
};

const UserPopup: React.FC<{ user: User; onClose: () => void }> = ({
  user,
  onClose,
}) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [points, setPoints] = useState(user.points_scored || 0);
  const [updatePoints] = useUpdate_PointsMutation();
  const { refetch } = useGetAllUsersQuery();

  const handlePointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPoints(parseInt(e.target.value) || 0);
  };

  const handleSavePoints = async () => {
    try {
      const { data } = await updatePoints({
        variables: {
          userId: user.user_id,
          pointsAwarded: points,
        },
      });
      if (data) {
        console.log("Points updated:", data.update_points);
        refetch();
      }
    } catch (error) {
      console.error("Error updating points:", error);
    }
  };

  const renderField = (key: string, value: any, icon: React.ReactNode) => {
    if (key === "points_scored") {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          key={key}
          className={`p-4 rounded-lg ${
            isDarkMode ? "bg-gray-700" : "bg-gray-50"
          } flex items-center overflow-hidden mb-4`}
        >
          <div className="mr-4 text-blue-500">{icon}</div>
          <div className="flex-grow">
            <span
              className={`font-semibold capitalize ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              {key.replace(/_/g, " ")}:
            </span>
            <input
              type="number"
              value={points}
              onChange={handlePointsChange}
              className={`ml-2 w-20 px-2 py-1 rounded ${
                isDarkMode ? "bg-gray-600 text-white" : "bg-white text-gray-900"
              } border ${isDarkMode ? "border-gray-500" : "border-gray-300"}`}
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSavePoints}
            className={`ml-4 p-2 rounded ${
              isDarkMode
                ? "bg-blue-600 hover:bg-blue-700"
                : "bg-blue-500 hover:bg-blue-600"
            } text-white`}
          >
            <Save size={20} />
          </motion.button>
        </motion.div>
      );
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        key={key}
        className={`p-4 rounded-lg ${
          isDarkMode ? "bg-gray-700" : "bg-gray-50"
        } flex items-center overflow-hidden mb-4`}
      >
        <div className="mr-4 text-blue-500">{icon}</div>
        <div>
          <span
            className={`font-semibold capitalize ${
              isDarkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            {key.replace(/_/g, " ")}:
          </span>
          <span
            className={`ml-2 ${isDarkMode ? "text-gray-100" : "text-gray-900"}`}
          >
            {value !== null && value !== undefined && value !== ""
              ? value.toString()
              : "Not provided"}
          </span>
        </div>
      </motion.div>
    );
  };

  const renderSAField = (key: string, value: any) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        key={key}
        className={`p-4 rounded-lg ${
          isDarkMode ? "bg-gray-700" : "bg-gray-50"
        } mb-4`}
      >
        <div className="flex items-center mb-2">
          <Bookmark size={24} className="text-blue-500 mr-2" />
          <span
            className={`font-semibold capitalize ${
              isDarkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            {key.replace(/_/g, " ")}:
          </span>
        </div>
        <div
          className={`ml-8 ${isDarkMode ? "text-gray-100" : "text-gray-900"}`}
        >
          {value !== null && value !== undefined && value !== ""
            ? value.toString()
            : "Not provided"}
        </div>
      </motion.div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: "spring", damping: 25, stiffness: 500 }}
        className={`${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto relative p-8`}
      >
        <button
          onClick={onClose}
          className={`absolute top-6 right-6 ${
            isDarkMode
              ? "text-gray-300 hover:text-gray-100"
              : "text-gray-500 hover:text-gray-700"
          } transition-colors duration-200`}
        >
          <X size={28} />
        </button>
        <div className="flex items-center mb-8">
          <div className="w-32 h-32 rounded-full mr-8 border-6 border-blue-500 bg-gray-200 flex items-center justify-center overflow-hidden">
            <UserIcon size={64} className="text-gray-500" />
          </div>
          <div>
            <h2
              className={`text-4xl font-bold mb-2 ${
                isDarkMode ? "text-white" : "text-gray-800"
              }`}
            >
              {user.username}
            </h2>
            <p className="text-2xl text-blue-500">{user.saarang_id}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {renderField("email", user.email_primary, <Mail size={24} />)}
          {renderField(
            "mobile_number",
            user.mobile_number_primary,
            <Phone size={24} />
          )}
          {renderField("isIITM", user.isIITM, <Award size={24} />)}
          {renderField("college", user.college, <Book size={24} />)}
          {renderField(
            "points_scored",
            user.points_scored,
            <Award size={24} />
          )}
          {renderField("state", user.state, <MapPin size={24} />)}
          {renderField(
            "year_of_study",
            user.year_of_study,
            <Calendar size={24} />
          )}
          {renderField(
            "sa_referral",
            user.sa_referral,
            <UserRoundPlus size={24} />
          )}
        </div>
        <div className="mb-8">
          <h3
            className={`text-xl font-bold mb-4 ${
              isDarkMode ? "text-white" : "text-gray-800"
            }`}
          >
            SA Answers
          </h3>
          <div>
            {renderSAField("sa_answer", user.sa_answer)}
            {renderSAField("sa_answer2", user.sa_answer2)}
            {renderSAField("sa_answer3", user.sa_answer3)}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const ThemeToggle: React.FC = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={toggleTheme}
      className={`p-3 rounded-full ${
        isDarkMode ? "bg-gray-700 text-yellow-300" : "bg-gray-200 text-gray-800"
      } transition-colors duration-200`}
    >
      {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
    </motion.button>
  );
};

const Admin: React.FC = () => {
  const [logoutMutation, { data: lData, loading: lLoading, error: lError }] =
    useLogoutMutation();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
  const [showCA, setShowCA] = useState<boolean>(false);

  const { data: userData, refetch } = useGetAllUsersQuery();
  const generateCSV = (users: User[]) => {
    const csvRows = [
      [
        // "User Id",
        "username",
        "year of study",
        "mobile number",
        "email primary",
        "isIITM",
        "state",
        "points scored",
        "saarang id",
        "sa ans 1",
        "sa ans 2",
        "sa ans 3",
      ],
      ...users.map((user) => [
        
        user.username || "",
        user.year_of_study || "",
        user.mobile_number_primary || "",
        user.email_primary || "",
        user.isIITM || "",
        user.state || "",
        user.points_scored || "",
        user.saarang_id || "",
        user.sa_answer || "",
        user.sa_answer2 || "",
        user.sa_answer3 || "",
      ]),
    ];

    return csvRows.map((row) => row.join(",")).join("\n");
  };
  const handleDownloadCSV = () => {
    const csv = generateCSV(filteredUsers);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    if (showCA) {
      a.download = "applicants.csv";
    } else {
      a.download = "ambassidors.csv";
    }

    a.click();
    window.URL.revokeObjectURL(url);
  };
  useEffect(() => {
    if (userData?.getAllUsers) {
      setUsers(userData.getAllUsers as User[]);
    }
  }, [userData]);

  useEffect(() => {
    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(
      filtered.filter((user) =>
        showCA
          ? user.positions ===
            "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000010"
          : user.positions ===
            "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100"
      )
    );
  }, [searchTerm, users, showCA]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleLogout = async () => {
    await logoutMutation();
    navigate("/");
  };
  const handleTasks = async () => {
    navigate("/admin/tasks");
  };
  const handleSubmissions = async () => {
    navigate("/admin/submissions");
  };

  const handleSetCA = (user: User) => {
    const updatedUsers = users.map((u) =>
      u.user_id === user.user_id
        ? {
            ...u,
            positions:
              "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100",
          }
        : u
    );
    setUsers(updatedUsers);
  };

  const toggleShowCA = () => {
    setShowCA(!showCA);
  };

  return (
    <ThemeContext.Provider
      value={{ isDarkMode, toggleTheme: () => setIsDarkMode(!isDarkMode) }}
    >
      <div
        className={`min-h-screen ${
          isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
        } pt-24 mt-20`}
      >
        <motion.div
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          className={`fixed top-0 left-0 right-0 ${
            isDarkMode ? "bg-gray-800" : "bg-white"
          } shadow-lg z-10`}
        >
          <div className="container mx-auto px-6 py-4 ">
            <div className="flex justify-between items-center ">
              <h1 className="text-4xl font-extrabold text-gray-900 dark:text-gray-100 bg-gradient-to-r from-blue-500 to-teal-500 text-transparent bg-clip-text p-2">
                SA ADMIN PANEL
              </h1>
              <div className="flex items-center space-x-4">
                <ThemeToggle />
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={toggleShowCA}
                  className={`px-4 py-2 rounded-lg border  ${
                    isDarkMode
                      ? "border-gray-600 hover:bg-gray-700"
                      : "border-gray-300 hover:bg-gray-200"
                  } transition-colors duration-200 flex items-center  `}
                >
                  {showCA ? (
                    <>
                      <ToggleRight className="mr-2" />
                      Show SA
                    </>
                  ) : (
                    <>
                      <ToggleLeft className="mr-2 " />
                      Show Applicants
                    </>
                  )}
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleTasks}
                  className={`px-4 py-2 rounded-lg border ${
                    isDarkMode
                      ? "border-blue-400 bg-blue-400 hover:bg-blue-700 text-black hover:text-white"
                      : "border-blue-400 bg-blue-400 hover:bg-blue-700 text-white"
                  } transition-colors duration-200`}
                >
                  Tasks
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleSubmissions}
                  className={`px-4 py-2 rounded-lg border ${
                    isDarkMode
                      ? "border-blue-400 bg-blue-400 hover:bg-blue-700 text-black hover:text-white"
                      : "border-blue-400 bg-blue-400 hover:bg-blue-700 text-white"
                  } transition-colors duration-200`}
                >
                  Submissions
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleDownloadCSV}
                  className={`px-4 py-2 rounded-lg border ${
                    isDarkMode
                      ? "border-blue-400 bg-blue-400 hover:bg-blue-700 text-black hover:text-white"
                      : "border-blue-400 bg-blue-400 hover:bg-blue-700 text-white"
                  } transition-colors duration-200`}
                >
                  Download CSV {showCA ? " of Applicants" : " of SA"}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleLogout}
                  className={`px-4 py-2 rounded-lg border ${
                    isDarkMode
                      ? "border-red-600 bg-red-600 hover:bg-red-700 text-white"
                      : "border-red-500 bg-red-500 hover:bg-red-600 text-white"
                  } transition-colors duration-200`}
                >
                  Logout
                </motion.button>
              </div>
            </div>
            <div className="mt-6 relative">
              <input
                type="text"
                placeholder={
                  showCA ? "search applicants" : "search Ambassadors"
                }
                value={searchTerm}
                onChange={handleSearch}
                className={`w-full p-3 pl-12 rounded-lg border text-lg ${
                  isDarkMode
                    ? "bg-gray-700 text-white border-gray-600"
                    : "bg-white text-gray-800 border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200`}
              />
              <Search
                size={24}
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                }`}
              />
            </div>
          </div>
        </motion.div>

        <div className="container mx-auto px-6 pt-8">
          <AnimatePresence>
            {filteredUsers.map((user) => (
              <UserRibbon
                key={user.user_id}
                user={user}
                onClick={(selected) => setSelectedUser(selected)}
                onSetCA={handleSetCA}
              />
            ))}
          </AnimatePresence>
        </div>

        {selectedUser && (
          <AnimatePresence>
            <UserPopup
              user={selectedUser}
              onClose={() => setSelectedUser(null)}
            />
          </AnimatePresence>
        )}
      </div>
    </ThemeContext.Provider>
  );
};

export default Admin;
